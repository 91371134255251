<template>
	<AddEditForm :headerTitle="$t('pages.bank.addBank')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-addBank" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="bankDetail != null">
				<template v-slot:body>
					<CForm>
						<CInput :placeholder="$t('pages.bank.enterBankName')" v-model="bankDetail.bankName" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('pages.bank.bankName') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<CInput :placeholder="$t('pages.bank.enterBankCode')" v-model="bankDetail.bankCode" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('pages.bank.bankCode') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>
						
						<CInput :label="$t('pages.bank.swiftCode')" :placeholder="$t('pages.bank.enterswiftCode')" v-model="bankDetail.swiftCode" horizontal></CInput>


					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>	
    import i18n from '@/plugins/i18n'
	import { mapGetters } from 'vuex'   
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

	export default {
		name: 'EditBank',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' }
			}
		},
		computed: {
            ...mapGetters('bank', ['bankDetail']),
		},
		components: {
            Accordion, AddEditForm
		},
		methods: {
            onSave() {
                this.onSaveNewBank();
            },
            onGoBack() {
                this.$router.push('/banks/list');
            },
			validator(val) {				
				return val ? val.length > 0 : false
            },						
			async onSaveNewBank() {
				
                if (!this.validator(this.bankDetail.bankName))
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.bank.enterBankName'));
					return;
				}

                if (!this.validator(this.bankDetail.bankCode)) {
                    this.$store.dispatch('alert/error', this.$t('pages.bank.enterBankCode'));
                    return;
                }
							               
                var response = await this.$store.dispatch("bank/saveBankInfo");

                if (response.success == true) {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.bank.noticeEditBankSuccess') }, { root: true });
                    this.onGoBack();
                }
                else {
                    let message = i18n.t('pages.bank.noticeEditBankFailed');
                    if (response.errorMessage === 'Existed') 
                        message = i18n.t('pages.bank.noticeExistedBankInfo');                    

                    this.$store.dispatch('alert/error', message, { root: true });
				}                               
			},			
		},
		mounted() {			
            this.$store.dispatch("bank/getBankDetail", this.$route.params.id);
		}
	}
</script>